import React, { useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';  // Importing the arrow down icon
import HeroImg from '../Assets/hero.webp';
import WaitlistButton from './waitlist/WaitlistButton';  // Make sure the path is correct

const Hero = () => {
  // State to control the visibility of the WaitlistButton
  const [showWaitlist, setShowWaitlist] = useState(true);

  const scrollToOutfit = () => {
    const outfitSection = document.getElementById("full-body-outfit");
    outfitSection.scrollIntoView({ behavior: "smooth" });
  };

  const handleJoinClick = () => {
    setShowWaitlist(false); // Hide the WaitlistButton when joined
    console.log('User joined the waitlist');
  };
  
  const handleWaitClick = () => {
    console.log('Show waitlist form');
  };

  return (
    <div className="relative">
      {/* Hero Section */}
      <div className="relative">
        {/* Hero Image */}
        <div className="relative h-[300px] sm:h-[80vh] lg:h-[100vh] mt-0 ">
          <img 
            src={HeroImg} 
            alt="Hero" 
            className="w-full h-full object-cover"  // Ensure full width and height coverage
          />
          {/* Gradient overlay */}
          <div className="absolute inset-0 bg-gradient-to-b from-[#3D291D] to-transparent z-10"></div>

          {/* Text and Button in the center of the image */}
          <div className="absolute inset-0 flex flex-col items-center justify-center px-4 z-20">
            <h1 className="text-[#FDF5F2] text-3xl sm:text-4xl md:text-6xl lg:text-8xl font-gothamBold text-center mt-32 sm:mt-12 lg:mt-32">
              A state of mind.
            </h1>

            {/* Adjusted Button for Mobile and Desktop */}
            <button onClick={scrollToOutfit}  className="text-[#FDF5F2] font-gothamBold text-sm sm:text-base md:text-xl lg:text-2xl border-2 border-white rounded-full px-4 sm:px-6 md:px-8 lg:px-10 py-1 sm:py-2 md:py-3 lg:py-4 hover:bg-white hover:text-[#3D291D] transition duration-300 mt-4 sm:mt-3 lg:mt-6 z-20">
              Shop now
            </button>

            {/* Arrow Down Icon */}
            <IoIosArrowDown className="text-[#FDF5F2] text-4xl sm:text-3xl md:text-5xl mt-2 sm:mt-3 lg:mt-7 z-20" />

            {/* Waitlist Button (Hidden on Mobile, visible on larger screens) */}
            <div className="z-20 hidden sm:block">
            {showWaitlist && (
          <WaitlistButton handleJoinClick={handleJoinClick} handleWaitClick={handleWaitClick} />
        )}
            </div>
          </div>
     
        </div>
      </div>
    </div>
  );
};

export default Hero;
