import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './waitlist.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaWhatsapp, FaTimes } from 'react-icons/fa';

const WaitlistButton = ({ handleJoinClick }) => {
  const [showText, setShowText] = useState(false);
  const [joined, setJoined] = useState(false);
  const [phone, setPhone] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let timer;
    timer = setTimeout(() => {
      setShowText(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, [isMobile]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (joined || !phone) return;

    try {
      const response = await fetch('https://saarconcept.net/api/whitelist/add/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone }),
      });

      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        if (response.ok) {
          setJoined(true);
          handleJoinClick();
        } else {
          const errorMessage = data.phone ? data.phone.join(', ') : 'An unknown error occurred';
          toast.error(` ${errorMessage}`);
        }
      } else {
        const text = await response.text();
        toast.error(` ${text}`);
      }
    } catch (error) {
      toast.error(` ${error.message}`);
    }
  };

  const handleCloseForm = () => {
    setShowText(false);
    setPhone('');
    handleJoinClick();  // This will hide the button in the parent component
  };
  
  const handleToggle = () => {
    setShowText(true);
  };

  return (
    <div
    id="waitlist"
      style={{
        textAlign: 'center',
        padding: '10px',
        maxWidth: '300px',
        margin: '0 auto',
      }}
    >
      {/* Desktop Version */}
      {!isMobile && (
        <button
          className="waitlist-button-login"
          onClick={handleToggle}
          style={{
            padding: joined ? '25px 16px' : showText ? '8px 16px' : '25px 24px',
            borderRadius: '30px',
            backgroundColor: '#FA8F21',
            color: '#FFFFFF',
            fontSize: joined ? '26px' : showText ? '10px' : '23px',
            fontWeight: joined ? 'bold' : 'normal',
            fontFamily: joined ? 'Gotham Bold, sans-serif' : showText ? 'Gotham Light, sans-serif' : 'Gotham Bold, sans-serif',
            cursor: 'pointer',
            border: 'none',
            width: joined ? 'auto' : showText ? '360px' : '160px',
            textAlign: 'center',
            margin: '0 auto',
            transition: 'all 0.3s ease-in-out',
          }}
        >
          {joined ? (
            <>
              You joined<br />
              Contact us{' '}
              <a
                href="https://wa.me/905362887229"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#FFFFFF', textDecoration: 'none', marginLeft: '8px' }}
              >
                <FaWhatsapp size={28} style={{ verticalAlign: 'middle' }} />
              </a>
            </>
          ) : !showText ? (
            'Waitlist'
          ) : (
            <>
              <div className="phone-input-container" style={{ marginBottom: '10px' }}>
                <PhoneInput
                  international
                  defaultCountry="TR"
                  value={phone}
                  onChange={setPhone}
                />
              </div>
              <p
                style={{
                  fontFamily: 'Gotham Light, sans-serif',
                  fontSize: '10px',
                  color: '#FFFFFF',
                  lineHeight: '1.3',
                  margin: '0',
                  fontWeight: 'normal',
                }}
              >
                By providing your phone number, you consent to receive recurring automated marketing texts from this shop and its partners. Msg & data rates may apply. Reply HELP for help, STOP to cancel. See our TERMS OF SERVICE and PRIVACY POLICY.
                <br /> Make sure to join using a phone number that has WhatsApp.
              </p>
              <button
                className="join-button"
                onClick={handleSubmit}
                style={{
                  padding: '10px 16px',
                  borderRadius: '30px',
                  backgroundColor: 'white',
                  color: '#FA8F21',
                  fontSize: '13px',
                  fontWeight: 'bold',
                  fontFamily: 'Gotham Light, sans-serif',
                  cursor: 'pointer',
                  border: 'none',
                  width: 'auto',
                  textAlign: 'center',
                  margin: '0 auto',
                  marginTop: '6px',
                  marginLeft: '250px', // Adjust margin
                }}
              >
                Join
              </button>
            </>
          )}
          {showText && (
            <FaTimes
              onClick={handleCloseForm}
              style={{
                position: 'absolute',
                bottom: '20px',
                left: '30px', // Adjust for mobile
                color: '#FFFFFF',
                cursor: 'pointer',
                fontSize: '20px',
              }}
            />
          )}
        </button>
      )}

      {/* Mobile Version */}
      {isMobile && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <button
            className="waitlist-button-mobile"
            onClick={handleToggle}
            style={{
              padding: joined ? '16px 12px' : showText ? '5px 12px' : '12px 30px',
              borderRadius: '30px',
              backgroundColor: '#FA8F21',
              color: '#FFFFFF',
              fontSize: '13px',
              fontWeight: 'bold',
              fontFamily: 'Gotham Bold, sans-serif',
              cursor: 'pointer',
              border: 'none',
              width: 'auto',
              textAlign: 'center',
              margin: '0 auto',
              transition: 'all 0.3s ease-in-out',
            }}
          >
            {joined ? (
              <>
                You joined<br />
                Contact us{' '}
                <a
                  href="https://wa.me/905362887229"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#FFFFFF', textDecoration: 'none', marginLeft: '8px' }}
                >
                  <FaWhatsapp size={28} style={{ verticalAlign: 'middle' }} />
                </a>
              </>
            ) : !showText ? (
              'Waitlist'
            ) : (
              <>
                <div className="phone-input-container" style={{ marginBottom: '10px' }}>
                  <PhoneInput
                    international
                    defaultCountry="TR"
                    value={phone}
                    onChange={setPhone}
                  />
                </div>
                <p
                  style={{
                    fontFamily: 'Gotham Light, sans-serif',
                    fontSize: '12px',
                    color: '#FFFFFF',
                    lineHeight: '1.3',
                    margin: '0',
                    fontWeight: 'normal',
                  }}
                >
                  By providing your phone number, you consent to receive recurring automated marketing texts from this shop and its partners. Msg & data rates may apply. Reply HELP for help, STOP to cancel. See our TERMS OF SERVICE and PRIVACY POLICY.
                  <br /> Make sure to join using a phone number that has WhatsApp.
                </p>
                <button
                  className="join-button"
                  onClick={handleSubmit}
                  style={{
                    padding: '8px 12px',
                    borderRadius: '30px',
                    backgroundColor: 'white',
                    color: '#FA8F21',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    fontFamily: 'Gotham Light, sans-serif',
                    cursor: 'pointer',
                    border: 'none',
                    width: 'auto',
                    textAlign: 'center',
                    margin: '0 auto',
                    marginTop: '6px',
                  }}
                >
                  Join
                </button>
              </>
            )}
          </button>
          {showText && (
            <FaTimes
              onClick={handleCloseForm}
              style={{
                position: 'absolute',
                top: '8px',
                left: '10px', // Adjust for mobile
                color: '#FFFFFF',
                cursor: 'pointer',
                fontSize: '24px',
              }}
            />
          )}
        </div>
      )}
  <ToastContainer
  style={{ zIndex: 9999 }}  // Set maximum z-index value
/>
    </div>
  );
};

export default WaitlistButton;
