import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar';
import Login from './pages/login/login';
import SignUp from './pages/signup/signup'; 
import Aboutus from './pages/aboutus/aboutus';
import Footer from './components/footer';

const App = () => {
  return (
    <div className="flex flex-col min-h-screen"> {/* Flexbox container */}
      <Router>
        <Navbar /> {/* Add the Navbar */}
        <div className="flex-grow"> {/* This grows to fill available space */}
          <Routes>
            <Route path="/" element={<Aboutus />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
        <Footer /> {/* Footer sticks to the bottom */}
      </Router>
    </div>
  );
};

export default App;
