import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import outfit1 from '../Assets/navy 2.webp'; // Ensure correct paths
import outfit2 from '../Assets/olive 2.webp';
import outfit3 from '../Assets/beige 2.webp';
import { LuHeart } from 'react-icons/lu'; // Import LuHeart icon from react-icons

const FullBodyOutfit = () => {
  const outfits = [outfit1, outfit2, outfit3]; // Array of images
  const [centerOutfit, setCenterOutfit] = useState(1); // Initially center the second image
  const [movementDistance, setMovementDistance] = useState(100); // Default to mobile distance

  const handleOutfitClick = (index) => {
    setCenterOutfit(index); // Set clicked image as the center
  };

  // Array of outfit descriptions corresponding to the images
  const outfitDescriptions = ['Navy blue full outfit', 'Olive green full outfit', 'Beige full outfit'];

  // Function to update movement distance based on window size
  const updateMovementDistance = useCallback(() => {
    if (window.innerWidth < 768) {
      setMovementDistance(100); // 100px for mobile
    } else {
      setMovementDistance(170); // 150px for desktop
    }
  }, []);

  // Add event listener for window resize
  useEffect(() => {
    updateMovementDistance(); // Set initial distance based on current width
    window.addEventListener('resize', updateMovementDistance); // Update distance on resize
    return () => window.removeEventListener('resize', updateMovementDistance); // Clean up on unmount
  }, [updateMovementDistance]);

  return (
    <div id="full-body-outfit" className="flex flex-col items-center py-12 bg-[#FDF5F2]">
      {/* Heading */}
      <h1  className="text-2xl md:text-3xl font-gothamBold text-[#3D291D] mb-12 text-center">
        BUY FULL OUTFIT NOW!
      </h1>

      {/* Images Container */}
      <div className="relative flex flex-col items-center w-full max-w-screen-lg h-[400px] md:h-[600px] bg-[#FDF5F2] px-4 md:px-0">
        {/* Images */}
        <div className="relative flex justify-center items-center w-full h-full">
          {outfits.map((outfit, index) => {
            const isCenter = index === centerOutfit;
            const isLeft = index === (centerOutfit - 1 + outfits.length) % outfits.length;
            const isRight = index === (centerOutfit + 1) % outfits.length;

            return (
              <motion.img
                key={index}
                src={outfit}
                alt={`Outfit ${index + 1}`}
                className={`absolute object-cover rounded-lg cursor-pointer ${isCenter ? 'z-20' : 'z-10'} w-[150px] h-[300px] md:w-[250px] md:h-[500px]`}
                initial={{ scale: 0.8 }}
                animate={{
                  scale: isCenter ? (index === 0 ? 1.3 : 1.1) : 0.8,
                  x: isCenter
                    ? 0
                    : isLeft
                    ? `-${movementDistance}px`  // Movement for left image
                    : isRight
                    ? `${movementDistance}px`  // Movement for right image
                    : '0',
                }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                onClick={() => handleOutfitClick(index)}
              />
            );
          })}
        </div>

        {/* Description and Price Container */}
        <div className="relative flex flex-col items-center justify-center w-full mt-4 bg-[#FDF5F2]">
          <p className="text-xl md:text-3xl font-gothamBold text-[#3D291D] text-center mt-4">
            {outfitDescriptions[centerOutfit]}
          </p>

          {/* Mobile Layout: Prices */}
          <div className="flex justify-center items-center w-full md:hidden">
            <span className="text-lg font-gothamLight text-[#3D291D] mr-24">
              130$
            </span>
            <span className="text-lg font-gothamBold text-[#7DDED9] ">
              109.99$
            </span>
          </div>

          {/* Mobile Layout: Button and Heart Icon under Prices */}
          <div className="flex justify-center items-center w-full mt-2 md:hidden">
            <button className="px-4 py-2 bg-[#FA8F21] text-white rounded-full font-gothamBold text-sm mr-16 mt-2">
              Add to Cart
            </button>
            <span className="text-2xl font-gothamBold text-[#FA8F21] mt-2 ">
              <LuHeart />
            </span>
          </div>

          {/* Price for desktop */}
          <span className="hidden md:block text-xl md:text-3xl font-gothamBold text-[#7DDED9] mt-10 absolute md:right-40 mr-48 top-10">
            109.99$
          </span>
          <span className="hidden md:block text-lg md:text-xl font-gothamLight text-[#3D291D] mt-10 absolute md:left-40 ml-48 top-10">
            130$
          </span>

          {/* Add to Cart Button for Desktop */}
          <button className="hidden md:block px-4 md:px-6 py-2 bg-[#FA8F21] text-white rounded-full font-gothamBold text-sm md:text-lg mt-2 absolute md:left-40 ml-48 top-32">
            Add to Cart
          </button>

          {/* Favorite Icon for Desktop */}
          <span className="hidden md:block text-2xl md:text-3xl font-gothamBold text-[#FA8F21] mt-2 absolute md:right-40 mr-48 top-32">
            <LuHeart />
          </span>
        </div>
      </div>

      <hr className="w-full border-t-2 border-[#3D291D] mt-20 md:mt-32" />
    </div>
  );
};

export default FullBodyOutfit;
