import React from 'react';
import col1 from '../Assets/col1.webp';
import col2 from '../Assets/col2.webp';
import col3 from '../Assets/col3.webp';
import col4 from '../Assets/col4.webp';
import col5 from '../Assets/col5.webp';
import col6 from '../Assets/col6.webp';
import col7 from '../Assets/col7.webp';

const Collection = () => {
  return (
    <div className="w-full overflow-x-hidden mt-0 pt-0">
      <h1 className='font-gothamBold text-[#3D291D] text-center mb-12'>Our Collection</h1>
      
      {/* Desktop layout */}
      <div className="hidden md:flex flex-col md:flex-row space-x-2"> 
        {/* Column for col1 and col4 */} 
        <div className="flex flex-col md:w-2/3">
          <img
            src={col1}
            alt="Collection Image 1"
            className="w-full h-auto object-cover mb-2"
          />
          <div className="flex space-x-2">
            <img
              src={col4}
              alt="Collection Image 4"
              className="w-[37.2%] h-auto object-cover md:scale-[0.9] lg:scale-100"
            />
            <img
              src={col5}
              alt="Collection Image 5"
              className="w-[37.2%] h-auto object-cover md:scale-[0.9] lg:scale-100"
            />
            <img
              src={col6}
              alt="Collection Image 6"
              className="w-[37.2%] h-auto object-cover md:scale-[0.9] lg:scale-100"
            />
            <img
              src={col7}
              alt="Collection Image 7"
              className="w-[37.2%] h-auto object-cover md:scale-[0.9] lg:scale-100"
            />
          </div>
        </div>

        {/* Image 2 with increased width */}
        <div className="flex flex-col w-[34%] h-[80%] mr-2"> {/* Added margin-right */}
          <img
            src={col2}
            alt="Collection Image 2"
            className="w-full h-full object-cover" // Responsive height
          />
        </div>

        {/* Image 3 */}
        <img
          src={col3}
          alt="Collection Image 3"
          className="w-1/3 h-auto object-cover md:scale-[0.9] lg:scale-100"
        />
      </div>

      {/* Mobile layout */}
      <div className="block md:hidden mt-4">
        <div className="flex flex-col">
          {/* Top row */}
          <div className="flex flex-row justify-between mb-2">
            <img
              src={col2}
              alt="Collection Image 2"
              className="w-[48%] h-auto object-cover" // Responsive width
            />
            <img
              src={col3}
              alt="Collection Image 3"
              className="w-[48%] h-auto object-cover" // Responsive width
            />
          </div>
          {/* Middle row */}
          <div className="flex flex-row justify-between mb-2">
            <img
              src={col1}
              alt="Collection Image 1"
              className="w-[48%] h-auto object-cover" // Responsive width
            />
            <img
              src={col5}
              alt="Collection Image 5"
              className="w-[48%] h-auto object-cover" // Responsive width
            />
          </div>
          {/* Bottom row */}
          <div className="flex flex-row justify-between">
            <img
              src={col6}
              alt="Collection Image 6"
              className="w-[48%] h-auto object-cover" // Responsive width
            />
            <img
              src={col7}
              alt="Collection Image 7"
              className="w-[48%] h-auto object-cover" // Responsive width
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-8">
        <button className="text-[#3D291D] font-gothamBold text-2xl border-3 border-[#3D291D] rounded-full px-6 py-2 hover:bg-white hover:text-[#3D291D] transition duration-300">
          Explore
        </button>
      </div>
      <hr className="w-full border-t-2 border-[#3D291D] mt-12" />
    </div>
  );
};

export default Collection;
